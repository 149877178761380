<template>
  <div>
    <template v-if="!loading">
      <div class="md-layout">
        <!-- Student INFO -->
        <div class="md-layout-item md-size-30">
          <md-card class="md-card-profile">
            <div class="md-card-avatar">
              <div v-if="!student.profile_image">
                <img
                  :src="avatar"
                  class="picture-src"
                  title=""
                >
              </div>
              <div v-else>
                <img :src="student.profile_image.url">
              </div>
            </div>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item">
                  <h3 class="mt-2 mb-4">
                    <b>{{ student.first_name }} {{ student.last_name }}</b>
                  </h3>
                </div>
              </div>
              <div class="md-layout text-left">
                <div class="md-layout-item">
                  <b>Student number: </b> {{ student.student_number }}<br>
                  <b>Email: </b> {{ student.email }}<br>
                  <b>Nationality: </b> {{ student.country.nationality }}<br>
                  <b>Birth: </b> {{ student.date_of_birth }}<br>
                  <b>Gender: </b> {{ student.gender === "M" ? "Male" : "Female"
                  }}<br>
                  <b>Phone: </b> {{ student.phone }}<br>
                  <b>Passport number: </b>
                  {{ getDefaultPassport(student.passports) }} <br>
                  <template v-if="student.localAddress">
                    <b>Local Address: </b>
                    {{ student.localAddress.address }},
                    {{ student.localAddress.city }},
                    {{ student.localAddress.county }},
                    {{ student.localAddress.country.name }},
                    {{ student.localAddress.zip_code }}
                    <br>
                  </template>
                  <b>Emergency Contact: </b>
                  {{ student.emergency_contact }}
                  {{
                    student.emergency_contact_name
                      ? `(${student.emergency_contact_name})`
                      : ""
                  }}
                  <br>
                  <b>Agent: </b>
                  {{ selectedProgramme.agent.first_name }}
                  {{ selectedProgramme.agent.last_name }}
                  <br>
                  <b>Status: </b> {{ selectedProgramme.status }}<br>
                  <b>Visa: </b> {{ parseVisaName(selectedProgramme.visa_type)
                  }}<br>
                  <b>Biometric Saved: </b>
                  {{ student.biometric_saved ? "YES" : "NO" }}<br>
                  <b>App Installed: </b>
                  {{ student.app_registration_date ? "YES" : "NO" }}
                  <template v-if="student.app_registration_date">
                    (EMAIL: {{ student.app_email }})<br>
                  </template>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>

        <div class="md-layout-item md-size-70">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <!-- Programme Selector -->
              <StudentProgrammesSelect
                v-model="selProgrammeId"
                :student-id="$route.params.id"
              />
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <!-- Programme Progress INFO -->
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <StudentCourseProgress :programme-id="selProgrammeId" />
                </div>

                <!-- Package Items -->
                <div class="md-layout-item md-size-50">
                  <md-card>
                    <md-card-header class="pb-0">
                      <h4 class="title">
                        Package Items
                      </h4>
                    </md-card-header>
                    <md-card-content class="pt-0 packageItemsContainer">
                      <div
                        v-for="item in selectedPackageItems"
                        :key="`${selectedProgramme.programme_id}${item.package_item_id}`"
                        class="md-layout"
                      >
                        <div
                          class="md-layout-item md-size-35 md-small-size-100"
                        >
                          <md-field class="md-form-group">
                            <md-icon>description</md-icon>
                            <label>Item</label>
                            <md-input
                              :value="item.name"
                              type="text"
                              disabled
                            />
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-20 md-small-size-100"
                        >
                          <FormNumber
                            currency
                            :value="item.package_package_item.price"
                            icon="euro"
                            label="Price"
                            disabled
                            with-zero-mask
                          />
                        </div>
                        <div
                          class="md-layout-item md-size-20 md-small-size-100"
                        >
                          <FormNumber
                            :value="item.package_package_item.units"
                            icon="tag"
                            label="Units"
                            disabled
                          />
                        </div>
                        <div
                          class="md-layout-item md-size-25 md-small-size-100"
                        >
                          <small v-if="item.package_package_item.used_at">
                            Used ({{
                              dateFormat(item.package_package_item.used_at)
                            }})
                          </small>
                        </div>
                      </div>
                    </md-card-content>
                  </md-card>

                  <StudentComments :student="student" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sections -->
      <div class="md-layout">
        <div class="md-layout">
          <div class="md-layout-item md-size-20">
            <md-card class="md-card-pricing">
              <h6 class="category">
                Files
              </h6>

              <md-card-content>
                <div class="md-card-icon">
                  <md-icon>save</md-icon>
                </div>
              </md-card-content>
              <md-card-actions class="text-center">
                <router-link
                  :to="{ name: 'StudentFiles' }"
                  tag="md-button"
                  class="md-info md-round"
                >
                  Enter
                </router-link>
              </md-card-actions>
            </md-card>
          </div>
          <div class="md-layout-item md-size-20">
            <md-card class="md-card-pricing">
              <h6 class="category">
                Financial
              </h6>

              <md-card-content>
                <div class="md-card-icon">
                  <md-icon>euro</md-icon>
                </div>
              </md-card-content>
              <md-card-actions class="text-center">
                <router-link
                  :to="{ name: 'StudentFinancial' }"
                  tag="md-button"
                  class="md-info md-round"
                >
                  Enter
                </router-link>
              </md-card-actions>
            </md-card>
          </div>
          <div class="md-layout-item md-size-20">
            <md-card class="md-card-pricing">
              <h6 class="category">
                Profile
              </h6>

              <md-card-content>
                <div class="md-card-icon">
                  <md-icon>account_box</md-icon>
                </div>
              </md-card-content>
              <md-card-actions class="text-center">
                <router-link
                  :to="{ name: 'StudentProfile' }"
                  tag="md-button"
                  class="md-info md-round"
                >
                  Enter
                </router-link>
              </md-card-actions>
            </md-card>
          </div>
          <div class="md-layout-item md-size-20">
            <md-card class="md-card-pricing">
              <h6 class="category">
                Programmes
              </h6>

              <md-card-content>
                <div class="md-card-icon">
                  <md-icon>local_library</md-icon>
                </div>
              </md-card-content>
              <md-card-actions class="text-center">
                <router-link
                  :to="{ name: 'StudentProgrammes' }"
                  tag="md-button"
                  class="md-info md-round"
                >
                  Enter
                </router-link>
              </md-card-actions>
            </md-card>
          </div>
          <div class="md-layout-item md-size-20">
            <md-card class="md-card-pricing">
              <h6 class="category">
                Timeline
              </h6>

              <md-card-content>
                <div class="md-card-icon">
                  <md-icon>schedule</md-icon>
                </div>
              </md-card-content>
              <md-card-actions class="text-center">
                <router-link
                  :to="{ name: 'StudentTimeline' }"
                  tag="md-button"
                  class="md-info md-round"
                >
                  Enter
                </router-link>
              </md-card-actions>
            </md-card>
          </div>
        </div>
      </div>

      <div class="md-layout">
        <div class="md-layout-item md-size-100 text-center">
          <md-button
            class="md-primary"
            @click="$router.push({ name: 'Students' })"
          >
            Return
          </md-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { StudentProgrammesSelect } from '@/components/Inputs/selects';
import { FormNumber } from '@/components/Inputs';
import StudentComments from './StudentComments.vue';
import StudentCourseProgress from './StudentCourseProgress.vue';

const order = {
  Enrolled: 1,
  'Pre-Enrolled': 2,
  'On-Hold': 3,
  'Partially Paid': 4,
  'Fully Paid': 5,
  Unpaid: 6,
  'Waiting for Approval': 7,
  Inactive: 8,
  Holiday: 9,
  Expelled: 10,
  Completed: 11,
  Cancelled: 12,
  Finished: 13,
};

export default {
  components: {
    StudentComments,
    StudentProgrammesSelect,
    FormNumber,
    StudentCourseProgress,
  },
  data() {
    return {
      student: null,
      selProgrammeId: null,
      avatar: '/img/default-avatar.png',
      loading: true,
    };
  },
  computed: {
    selectedProgramme() {
      return this.student.programmes.filter(
        (x) => x.programme_id === this.selProgrammeId,
      )[0];
    },
    selectedPackageItems() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.selectedProgramme.package.items
        .sort((a, b) => (a.show_order > b.show_order ? 1 : -1));
    },
  },
  watch: {
    student(val) {
      this.selProgrammeId = val.programmes.sort((a, b) => {
        if (order[a.status] > order[b.status]) return 1;
        return -1;
      })[0].programme_id;
    },
  },
  mounted() {
    this.getStudent(this.$route.params.id);
  },
  methods: {
    getStudent(id) {
      this.loading = true;
      this.request(
        `${this.$API.STUDENTS}${id}`,
        'get',
        null,
        ({ data }) => {
          const aux = data;

          if (data.passports.length) {
            aux.passports = data.passports.map((x) => {
              const pport = x;
              pport.issue_date = moment(
                String(data.passports[0].issue_date),
              ).format(this.$store.getters['school_settings/settings'].date_format);
              pport.expiration_date = moment(
                String(data.passports[0].expiration_date),
              ).format(this.$store.getters['school_settings/settings'].date_format);

              return pport;
            });
          }

          if (data.addresses.length) {
            aux.localAddress = data.addresses.find(
              (x) => x.is_default && x.type === 'Local',
            );
          }

          aux.programmes = data.programmes.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));

          aux.date_of_birth = moment(data.date_of_birth).format(
            this.$store.getters['school_settings/settings'].date_format,
          );
          this.student = aux;
        },
        () => {
          this.loading = false;
        },
      );
    },
    getDefaultPassport(passports) {
      const defaultPassport = passports.find((passport) => passport.is_default);
      return defaultPassport ? defaultPassport.number : '-';
    },
  },
};
</script>

<style scoped lang="scss">
.success {
  color: green;
}
.error {
  color: red;
}
.label {
  .label-a,
  .label-b,
  .label-c {
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: sub;
  }

  .label-a {
    background-color: #2a9146;
  }
  .label-b {
    background-color: red;
  }
  .label-c {
    background-color: #e2eb38;
  }
}
</style>
<style scoped>
::v-deep .ct-series-a .ct-slice-pie {
  fill: #2a9146;
}
::v-deep .ct-series-b .ct-slice-pie {
  fill: red;
}
::v-deep .ct-series-c .ct-slice-pie {
  fill: #e2eb38;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.packageItemsContainer {
  height: 164px;
  overflow: auto;
}
</style>
