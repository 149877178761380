<template>
  <!-- Course Progress Chart -->
  <chart-card
    v-if="pieChartData"
    header-animation="false"
    :chart-data="pieChartData"
    :chart-options="{
      height: '230px',
      showLabel: false,
    }"
    chart-type="Pie"
    header-icon
    chart-inside-content
    background-color="blue"
  >
    <template slot="chartInsideHeader">
      <div class="card-icon">
        <md-icon>pie_chart</md-icon>
      </div>
      <h4 class="title">
        Course Progress
      </h4>
    </template>
    <template slot="footer">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <div class="label">
            <span class="label-a" />
            Present Days ({{ attendanceData.attended }})
          </div>
          <div class="label">
            <span class="label-b" />
            Absent Days ({{ attendanceData.absent }})
          </div>
          <div class="label">
            <span class="label-c" />
            Remaining Days ({{ attendanceData.total - attendanceData.performed }})
          </div>
        </div>
        <div class="flex-grow-1">
          <b>Total Classes: </b> {{ attendanceData.total }}
          <br>
          <b>Attendance Rate: </b>
          <b :class="attendanceData.rate > 85 ? 'success' : 'error'">
            {{ attendanceData.rate }}%
          </b>
          <br>
        </div>
      </div>
    </template>
  </chart-card>

  <md-card v-else>
    <md-card-header>
      <div class="card-icon">
        <md-icon>pie_chart</md-icon>
      </div>
      <h4 class="title">
        Course Progress
      </h4>
    </md-card-header>
    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item">
          <small>Select a programme with attendance to see the graph</small>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { ChartCard } from '@/components';

export default {
  components: {
    ChartCard,
  },
  props: {
    programmeId: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    progress: null,
  }),
  computed: {
    pieChartData() {
      if (!this.progress || !this.programmeId) return null;

      const data = {
        labels: [],
        series: [],
      };

      const {
        attended, absent, total, performed,
      } = this.attendanceData;

      data.labels.push(attended);
      data.series.push((attended * 100) / total);
      data.labels.push(absent);
      data.series.push((absent * 100) / total);

      if (total - performed > 0) {
        data.labels.push(total);
        data.series.push(((total - performed) * 100) / total);
      }

      return data;
    },
    attendanceData() {
      if (!this.progress || !this.programmeId) {
        return {
          rate: 0,
          total: 0,
          performed: 0,
          attended: 0,
          absent: 0,
        };
      }

      const {
        attendance_rate,
        total_course_days,
        performed_days,
        attended_days,
        absent_days,
      } = this.progress.find(
        (x) => x.programme_id === this.programmeId,
      )?.attendance;

      return {
        rate: attendance_rate,
        total: parseInt(total_course_days, 10),
        performed: parseFloat(performed_days),
        attended: parseFloat(attended_days),
        absent: parseFloat(absent_days),
      };
    },
  },
  mounted() {
    this.getProgress();
  },
  methods: {
    getProgress() {
      const student_id = this.$route.params.id;

      this.request(
        `${this.$API.STUDENTS + student_id}/${this.$API.COURSE_PROGRESS}`,
        'get',
        null,
        ({ data }) => {
          this.progress = data;
        },
      );
    },
  },
};
</script>
