<template>
  <md-card class="studentComments">
    <md-card-header>
      <h4 class="title">
        Note
      </h4>
    </md-card-header>

    <md-card-content>
      <FormTextArea
        v-if="editting"
        v-model="text"
        label="Write your comments here..."
      />
      <p
        v-else
        v-html="text"
      />

      <div class="floatting-button">
        <md-button
          v-if="!editting"
          class="md-info md-just-icon md-round"
          @click="editting = true"
        >
          <md-icon>edit</md-icon>
        </md-button>

        <md-button
          v-else
          class="md-success md-just-icon md-round"
          @click="saveComments"
        >
          <md-icon>save</md-icon>
        </md-button>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import FormTextArea from '@/components/Inputs/FormTextarea.vue';

export default {
  components: {
    FormTextArea,
  },
  props: {
    student: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    text: null,
    editting: false,
  }),
  mounted() {
    this.text = this.student.comments;
  },
  methods: {
    saveComments() {
      this.request(`students/${this.student.student_id}/comments`, 'put', {
        comments: this.text,
      }, () => {
        this.fireSuccess('Comments saved!');
        this.editting = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.studentComments ::v-deep .md-card-header {
  padding-bottom: 0;
}
.studentComments ::v-deep .md-card-content {
  height: 130px;
  position: relative;
  padding-top: 0;
  overflow-y: auto;

  .floatting-button {
    position: absolute;
    bottom: 10px;
    right: 15px;
  }
}
</style>
